@media screen and (max-width: 550px) {
  .auth_container {
    padding: 0;
  }
  .common_content {
    padding: 1rem;
  }
  .title_font {
    font: 600 1.1rem "Poppins", sans-serif;
  }
}