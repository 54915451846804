@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  --white: #fff;
  --darkBlue: #001529;
}

/**************** ck editor classes****************** */
.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content .ck-toolbar {
  background: var(--lightorange) !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  height: 50vh;
  overflow: auto;
  /* border: 2px solid var(--darkBlue) !important; */
  /* background: var(--lightorange) !important; */
  border-radius: 0 0 10px 10px !important;
}

.ck.ck-editor {
  border: 1px solid var(--darkBlue) !important;
}

/******************** common utitlity classes************ */
.common_container {
  width: 95%;
  margin: auto;
}

.common_content {
  max-height: 85vh;
  overflow: auto;
  padding: 1.5rem 2rem 2rem 2rem;
}

.common_input {
  height: 45px;
  outline: none;
  border: 1px solid var(--darkBlue);
}

.common_btn {
  border: none;
  height: 45px;
  background: var(--darkBlue);
  font: 500 0.9rem "Poppins", sans-serif;
  min-width: 200px;
  max-width: fit-content;
}

/******************** common utitlity fonts ************* */
.title_font {
  font: 600 1.5rem "Poppins", sans-serif;
}

.small_font {
  font: 500 0.9rem "Poppins", sans-serif;
}

/******************** Auth screen design ***************** */
.main_container {
  background: var(--darkBlue);
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth_container {
  /* background: var(--white); */
  padding: 2rem;
  border-radius: 10px;
  /* box-shadow: #ffffff36 0px 7px 29px 0px; */
}

.auth_logo>img {
  width: 50%;
}

/******************** Layout design********************** */
.header_div {
  height: 100px;
  padding: 0 1.5rem;
  background: var(--white);
  box-shadow: 0px 5px 10px #0000000d;
  display: flex;
  /* justify-content: end; */
  align-items: center;
}

.drop_down_btn {
  background: var(--darkBlue);
  font: 600 0.9rem "Poppins", sans-serif;
  /* border: 1px solid var(--darkBlue); */
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  padding: 0 0.5rem;
  width: max-content;
}

.logo_nav {
  height: 100px;
  text-align: center;
  line-height: 100px;
}

.siderbar_div {
  height: 100vh;
  box-shadow: 5px 0px 15px #0000000f;
  min-width: 250px;
}

.toggling_sider {
  min-width: 225px !important;
  height: 100vh;
}

.main_list_div {
  padding-bottom: 1rem;
  height: calc(100vh - 100px);
  overflow: auto;
}

.main_list_div>a {
  text-decoration: none;
}

.ant-input-out-of-range {
  color: #000 !important;
}


.ant-input-outlined.ant-otp-input {
  background: transparent !important;
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
  color: #fff;
}

.ant-otp {
  column-gap: 26px;
}

.unactive {
  font: 600 1.5rem "Poppins", sans-serif;
  cursor: pointer;
}

.active {
  font: 600 1.5rem "Poppins", sans-serif;
}

.ant-breadcrumb .ant-breadcrumb-separator {
  margin-inline: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1.3rem;
  margin-top: 4px;
}

.ant-switch.ant-switch-checked {
  background: #175c9c !important;
}

li.ant-menu-item.ant-menu-item-only-child {
  background-color: var(--darkBlue);
  margin: 0 0 0px !important;
  border-radius: 0 !important;
  color: #fff;
  height: 50px !important;
}

.ant-menu-light .ant-menu-item-selected {
  color: var(--darkBlue) !important;
  background: #fff !important;
  font-weight: 500;
}

.ant-menu-submenu-title {
  background-color: var(--darkBlue);

}

.ant-menu-submenu-title .ant-menu-title-content {
  color: #fff !important;
}

.ant-menu-submenu-title .ant-menu-title-content p {
  color: #fff !important;
}

.ant-menu-inline .ant-menu-submenu-title {
  margin: 0px;
  width: auto;
  border-radius: 0px;
}

.ant-menu .ant-menu-submenu-arrow {
  color: #fff !important;
}

.dashboard_card {
  height: 145px;
  background: 1px solid red;
  border-radius: 13px;
  box-shadow: -5px 11px 5px -1px #ebebeb;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  border: 2px dashed #142A42;
  height: 120px;
  width: 120px !important;

}

.ant-upload-list-item-container {
  height: 120px !important;
  width: 120px !important;
}
.no-wrap {
  white-space: nowrap;
}
.chartsMainDiv{
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, .2);
}